import React from 'react'
import styled from 'styled-components'
import { Divider, Grid, H, P, Image } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'

const steps = [
  {
    imagePath: 'screenshots/charity-iphone-1',
    title: 'Reach online, convert online',
    description:
      'Supporters are easier and cheaper to reach online, through mailing lists and social channels. By offering them a simple, free will product they can complete online in minutes, we’ve maximised conversion from lead to legacy.',
  },
  {
    imagePath: 'screenshots/charity-iphone-2',
    title: 'Optimise for legacy',
    description:
      'We use customised landing pages and an optimised legacy message to deliver phenomenal results. 24% of our wills contain legacies (2x industry standards) and 82% of gifts are residuary. ',
  },
  {
    imagePath: 'screenshots/charity-iphone-3',
    title: 'Report and iterate',
    description:
      'We report monthly on legacy performance and forecasting, as well as detailed demographic insights, giving fundraising and marketing teams the power to prove results and know when to scale campaigns. ',
  },
]

const StyledContent = styled(({ odd, ...props }) => (
  <Grid.Item
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    startColumnFromM={odd && 6}
    startColumnFromL={odd && 7}
  />
))`
  align-self: center;

  ${({ odd }) =>
    odd &&
    screenMin.m`
    text-align: right;
  `}
`

const StyledImageWrapper = styled(Grid.Item)`
  align-self: end;
`

const Steps = () => (
  <>
    {steps.map(({ imagePath, title, description }, i) => (
      <div key={title}>
        {!!i && <Divider container />}
        <Grid
          container
          containerPaddingTop={0}
          containerPaddingBottom={0}
          gap={0}
        >
          <StyledContent
            spanFromM={7}
            spanFromL={6}
            odd={i % 2}
            padding={['XXL', 0, 'L']}
            paddingFromM={['L', 0]}
          >
            <H size="L">{title}</H>
            <P>{description}</P>
          </StyledContent>
          <StyledImageWrapper
            spanFromM={5}
            spanFromL={6}
            centered
            paddingFromM={['L', 0, 0]}
          >
            <Image path={imagePath} width={271} stretch />
          </StyledImageWrapper>
        </Grid>
      </div>
    ))}
  </>
)

export default Steps
