import React from 'react'
import styled from 'styled-components'
import { Wrapper, Image, H, P, Button, ArrowRightIcon } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import WrapperWithEdge from 'components/WrapperWithEdge'
import ExternalLink from 'components/ExternalLink'
import { CHARITIES_REQUEST_CALLBACK_URL } from 'lib/charities/constants'

const StyledWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;
`

const StyledImage = styled(Image)`
  margin-top: ${GTR.L};
  margin-bottom: -30px;
  pointer-events: none;

  ${screenMin.m`
    position: absolute;
    bottom: 0;
    right: 0;
  `}

  ${screenMin.xl`
    right: 50%;
    margin-right: -600px;
  `}
`

const Footer = () => (
  <WrapperWithEdge direction="up" edgeBackgroundColor={COLOR.WHITE}>
    <StyledWrapper
      centered
      padding={['L', 0, 0]}
      paddingFromM={['L', 0, 'XXL']}
    >
      <H size="L" decorative>
        Get started
      </H>

      <P maxWidth={350} margin={[0, 'auto', 'L']} color={COLOR.BLACK}>
        Tell us a bit about you and your charity, and arrange a call today.
      </P>

      <Button.White
        href={CHARITIES_REQUEST_CALLBACK_URL}
        wide
        icon={ArrowRightIcon}
        tag={ExternalLink}
      >
        Get in touch
      </Button.White>

      <StyledImage path="illustrations/scene-snowy" width={450} stretch />
    </StyledWrapper>
  </WrapperWithEdge>
)

export default Footer
