import React from 'react'
import { Wrapper, Grid, H, P, Image, Button } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { CHARITY_AMOUNT_RAISED } from 'config'
import { CHARITIES_REQUEST_CALLBACK_URL } from 'lib/charities/constants'
import ExternalLink from 'components/ExternalLink'
import PreventOrphan from 'components/PreventOrphan'

const Hero = () => (
  <Wrapper background={COLOR.WHITE}>
    <Grid container centered centeredFromM={false} gap={0} gapFromL="L">
      <Grid.Item spanFromM={6}>
        <H size="XL" decorative>
          <PreventOrphan>
            Our customers have already pledged {CHARITY_AMOUNT_RAISED} for
            charity
          </PreventOrphan>
        </H>
      </Grid.Item>

      <Grid.Item
        spanFromM={6}
        startColumnFromM={7}
        centered
        spanRows={2}
        padding={['S', 0, 0]}
      >
        <Image
          path="illustrations/turtle-with-money"
          width={300}
          widthFromM={520}
          stretch
        />
      </Grid.Item>

      <Grid.Item spanFromM={6}>
        <P>
          We’ve done away with upfront costs and expensive campaigns to prove
          every charity, big or small, can effectively scale their legacy
          income.
        </P>
        <P margin={[0, 0, 'L']}>
          We use our in-house technology and custom analytics to maximise your
          legacy income and provide real-time data on gifts in wills of your
          legacy campaigns.
        </P>
        <Button.Primary
          href={CHARITIES_REQUEST_CALLBACK_URL}
          tag={ExternalLink}
          wide
        >
          Work with us
        </Button.Primary>
      </Grid.Item>
    </Grid>
  </Wrapper>
)

export default Hero
