import React from 'react'
import { Divider, Wrapper, H } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { CHARITY_AMOUNT_RAISED } from 'config'
import DefaultLayout from 'layouts/DefaultLayout'
import CharitiesList from 'components/CharitiesList'
import PledgeCounterBanner from 'components/PledgeCounterBanner'

import Hero from './Hero'
import Steps from './Steps'
import Footer from './Footer'

const Charities = () => (
  <DefaultLayout
    title="Work with us | Charities"
    description={`Partner with Farewill to raise legacy income for your charity. We work with over 180 partners and our customers have already pledged ${CHARITY_AMOUNT_RAISED} for charity.`}
  >
    <Hero />

    <PledgeCounterBanner />

    <Steps />

    <Divider />

    <Wrapper background={COLOR.WHITE}>
      <Wrapper container>
        <H size="M" decorative centered marginFromM={[0, 0, 'L']}>
          Proudly working with charities all over the UK
        </H>
        <CharitiesList withMoreCharitiesText withWrapper />
      </Wrapper>
    </Wrapper>

    <Footer />
  </DefaultLayout>
)

export default Charities
